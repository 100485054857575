:root {
  --fontSize100: 12px;
  --fontSize200: 14px;
  --fontSize300: 16px;
  --fontSize400: 18px;
  --fontSize500: 20px;
  --fontSize600: 24px;
  --fontSize700: 30px;
  --fontSize800: 36px;
  --fontSize900: 48px;
  --fontSize1000: 60px;
  --fontSize1100: 72px;
}
