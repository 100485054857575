:root {
  --shadow100: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsla(0, 0%, 0%, 0.24);
  --shadow200: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsla(0, 0%, 0%, 0.12);
  --shadow300: 0 10px 20px hsla(0, 0%, 0%, 0.15), 0 3px 6px hsla(0, 0%, 0%, 0.1);
  --shadow400: 0 15px 25px hsla(0, 0%, 0%, 0.15),
    0 5px 10px hsla(0, 0%, 0%, 0.5);
  --shadow500: 0 20px 40px hsla(0, 0%, 0%, 0.2);
  --shadowRaisedElement: inset 0 1px 2px 2px hsla(255, 100%, 100%, 0.15),
    0 1px 2px 2px hsla(0, 0%, 0%, 0.2);
  --shadowInsetElement: inset 0 2px 2px hsla(0, 0%, 0%, 0.1),
    inset 0 -2px 2px hsla(0, 0%, 100%, 0.4);
}
