:root {
  --grey100: hsl(210, 14%, 97%);
  --grey200: hsl(210, 15%, 95%);
  --grey300: hsl(210, 15%, 92%);
  --grey400: hsl(210, 13%, 88%);
  --grey500: hsl(210, 14%, 83%);
  --grey600: hsl(210, 11%, 71%);
  --grey700: hsl(210, 7%, 55%);
  --grey800: hsl(210, 9%, 31%);
  --grey900: hsl(210, 10%, 23%);
  --grey1000: hsl(213, 12%, 15%);

  --blue100: hsl(206, 89%, 96%);
  --blue200: hsl(206, 77%, 81%);
  --blue300: hsl(208, 59%, 62%);
  --blue400: hsl(208, 60%, 49%);
  --blue500: hsl(207, 64%, 38%);
  --blue600: hsl(208, 63%, 27%);
  --blue700: hsl(207, 53%, 15%);

  --green100: hsl(142, 76%, 94%);
  --green200: hsl(141, 66%, 79%);
  --green300: hsl(145, 56%, 65%);
  --green400: hsl(146, 55%, 49%);
  --green500: hsl(146, 62%, 38%);
  --green600: hsl(146, 65%, 28%);
  --green700: hsl(155, 59%, 20%);

  --red100: hsl(0, 71%, 95%);
  --red200: hsl(0, 77%, 81%);
  --red300: hsl(0, 69%, 64%);
  --red400: hsl(0, 69%, 52%);
  --red500: hsl(0, 70%, 42%);
  --red600: hsl(0, 67%, 32%);
  --red700: hsl(0, 60%, 24%);

  --yellow100: hsl(44, 85%, 97%);
  --yellow200: hsl(44, 86%, 91%);
  --yellow300: hsl(44, 88%, 80%);
  --yellow400: hsl(42, 86%, 67%);
  --yellow500: hsl(44, 56%, 51%);
  --yellow600: hsl(43, 64%, 33%);
  --yellow700: hsl(44, 66%, 22%);

  --teal100: hsl(178, 92%, 95%);
  --teal200: hsl(177, 66%, 79%);
  --teal300: hsl(178, 56%, 64%);
  --teal400: hsl(174, 48%, 46%);
  --teal500: hsl(174, 55%, 36%);
  --teal600: hsl(174, 58%, 25%);
  --teal700: hsl(179, 59%, 17%);
}
