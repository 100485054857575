:root {
  --scale100: 4px;
  --scale200: 8px;
  --scale300: 12px;
  --scale400: 16px;
  --scale500: 24px;
  --scale600: 32px;
  --scale650: 42px;
  --scale700: 48px;
  --scale800: 64px;
  --scale900: 96px;
  --scale1000: 128px;
  --scale1100: 256px;
  --scale1150: 320px;
  --scale1200: 384px;
  --scale1300: 512px;
  --scale1400: 640px;
  --scale1500: 768px;
  --minWidth: 320px;
}
